import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

export const getValidations = async (Type = 0) => {
	try {
		const QUERY_GET_VALIDATIONS = gql`
			query ($Type: ID!) {
				GetValidations(Type: $Type) {
					id
					createdAt
					updatedAt
					userProjectId
					type
					department
					validationLevelTs
					validationLevelPo
					validationLevelExp
					validationLevelDoc
					departmentName
					user {
						id
					}
				}
			}
		`;

		const {
			data: { GetValidations }
		} = await apollo.query({
			query: QUERY_GET_VALIDATIONS,
			variables: {
				Type: Type
			},
			fetchPolicy: 'no-cache'
		});

		return GetValidations;
	} catch (e) {
		console.log(e);
	}
};

export const getAdminValidations = async () => {
	try {
		const QUERY_GET_ADMIN_VALIDATIONS = gql`
			query {
				GetAdminValidations {
					id
					name
					firstName
					email
					validations {
						id
						userProjectId
						department
						departmentName
						validationLevelTs
						validationLevelPo
						validationLevelExp
					}
				}
			}
		`;
		const {
			data: { GetAdminValidations }
		} = await apollo.query({
			query: QUERY_GET_ADMIN_VALIDATIONS,
			fetchPolicy: 'no-cache'
		});
		return GetAdminValidations;
	} catch (e) {
		console.log(e);
	}
};

export const getDepartmentsForValidation = async () => {
	try {
		const QUERY_GET_DEPARTEMENT_FOR_VALIDATIONS = gql`
			query {
				GetDepartmentsForValidation {
					id
					message
					value
				}
			}
		`;
		const {
			data: { GetDepartmentsForValidation }
		} = await apollo.query({
			query: QUERY_GET_DEPARTEMENT_FOR_VALIDATIONS,
			fetchPolicy: 'no-cache'
		});

		return GetDepartmentsForValidation;
	} catch (e) {
		console.log(e);
	}
};

export const AddUpdValidation = async ({ ValidationId, UserId, ProjectId, UpdValidation, Replace }) => {
	if (isNil(ValidationId)) return;
	try {
		const mutation = gql`
			mutation ($ValidationId: ID!, $UserId: ID!, $ProjectId: ID!, $UpdValidation: ValidationInput!, $Replace: Boolean) {
				AddUpdValidation(ValidationId: $ValidationId, UserId: $UserId, ProjectId: $ProjectId, UpdValidation: $UpdValidation, Replace: $Replace) {
					id
					validationLevelExp
					validationLevelDoc
					validationLevelTs
					validationLevelPo
					department
					type
					userProjectId
					departmentName
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				ValidationId: +ValidationId,
				UserId: +UserId,
				ProjectId: ProjectId,
				UpdValidation: UpdValidation,
				Replace: Replace
			}
		});
		return data.AddUpdValidation;
	} catch (e) {
		console.error({ e });
	}
};

export const DeleteValidation = async ({ UserId, DepartmentNumber, Type }) => {
	if (isNil(UserId)) return;
	try {
		const mutation = gql`
			mutation ($UserId: ID!, $DepartmentNumber: Int!, $Type: ID!) {
				DelValidationByUser(UserId: $UserId, DepartmentNumber: $DepartmentNumber, Type: $Type)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				UserId,
				DepartmentNumber,
				Type
			}
		});
		return data.DelValidationByUser;
	} catch (e) {
		console.log('Il y a un erreur: ', e);
	}
};
